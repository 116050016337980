define([
  'app',
  '$location',
  'accessibleModalHelper',
  'uniqueIDHelper',
], function (app, $location, accessibleModalHelper, uniqueIDHelper) {
  let created = false;
  const emptyComponent = {
    init: () => {},
  };

  var addedToBasketModal = function () {
    var component = this;

    var _config = {
      attributes: {
        cndUrl: 'data-cdn-url',
        secureUrl: 'data-secure-url',
      },
      selectors: {
        error: '[data-error]',
        quantityLimitedReached: '[data-quantity-limit-reached]',
        quantityLimitedPartiallyReached:
          '[data-quantity-limit-partially-reached]',
        productTitle: '[data-product-title]',
        hasWorthByPrice: '[data-worth-by-price]',
        productPrice: '[data-product-price]',
        productWorth: '[data-product-worth]',
        productQuantity: '[data-product-quantity]',
        productImage: '[data-product-image]',
        productID: '[data-product-id]',
        basketTotal: '[data-basket-total]',
        basketTotalItems: '[data-basket-total-items]',
        productUrl: '[data-product-url]',
        closeModalButton: '[data-close]',
        continueShoppingButton: '[data-continue-shopping]',
        viewBasketButton: '[data-view-basket]',
        recommendations: '[data-recommendations]',
        recommendationsItem: '[data-recommendations-item]',
        responsiveBasketComponent: '[data-component=responsiveBasket]',
        v3ResponsiveBasketPage: '[data-component=v3ResponsiveBasket]',
        loyaltyPoints: '[data-loyalty-points]',
        defaultButtons: '[data-default-buttons]',
        personalisedMessage: '[data-product-personalised-message]',
        personalisedButtons: '[data-personalised-buttons]',
        personalisedPrimary: '[data-personalised-primary]',
        personalisationSummaryText: '[data-personalisation-summary-text]',
      },
      subscribeChannels: {
        productAdded: 'addedToBasketModal/productAdded',
        showError: 'addedToBasketModal/showError',
        hideError: 'addedToBasketModal/hideError',
        reinitialise: 'addedToBasketModal/reinitialise',
      },
      classes: {
        showError: 'addedToBasketModal_error-show',
        showWarning: 'addedToBasketModal_warning-show',
        showModal: 'addedToBasketModal-show',
        emptyRecommendations: 'addedToBasketModal_productRecommendations-empty',
        personalisedMessage: 'athenaAddedToBasketModal_personalisedText',
      },
      messages: {
        maxQuantityReached: app.utils.getProperty(
          'maxQuantityReached',
          'addedToBasketModal'
        ),
        partialMaxQuantityReached: app.utils.getProperty(
          'partialMaxQuantityReached',
          'addedToBasketModal'
        ),
      },
      recommendationsResetHTML:
        '<span class="addedToBasketModal_loading"><span class="addedToBasketModal_loadingSpinny"></span></span>',
    };

    var _init = function (element, userDriven) {
      component.element = element;
      component.cndUrl = app.element.getAttribute(
        component.config.attributes.cndUrl,
        component.element
      );
      component.secureUrl = app.element.getAttribute(
        component.config.attributes.secureUrl,
        component.element
      );
      component.error = component.element.querySelector(
        component.config.selectors.error
      );
      component.quantityLimitedReached = component.element.querySelector(
        component.config.selectors.quantityLimitedReached
      );
      component.quantityLimitedPartiallyReached =
        component.element.querySelector(
          component.config.selectors.quantityLimitedPartiallyReached
        );
      component.closeModalButton = component.element.querySelector(
        component.config.selectors.closeModalButton
      );
      component.continueShoppingButton = component.element.querySelector(
        component.config.selectors.continueShoppingButton
      );
      component.viewBasketButton = component.element.querySelector(
        component.config.selectors.viewBasketButton
      );
      component.recommendationsContainer = component.element.querySelector(
        component.config.selectors.recommendations
      );

      component.onBasketPage =
        document.querySelector(
          component.config.selectors.responsiveBasketComponent
        ) ||
        document.querySelector(
          component.config.selectors.v3ResponsiveBasketPage
        );

      component.productTitle = component.element.querySelector(
        component.config.selectors.productTitle
      );
      component.productHasWorthByPrice = component.element.querySelector(
        component.config.selectors.hasWorthByPrice
      );
      component.productPrice = component.element.querySelector(
        component.config.selectors.productPrice
      );
      component.productWorth = component.element.querySelector(
        component.config.selectors.productWorth
      );
      component.productQuantity = component.element.querySelector(
        component.config.selectors.productQuantity
      );
      component.basketTotal = component.element.querySelector(
        component.config.selectors.basketTotal
      );
      component.basketTotalItems = component.element.querySelector(
        component.config.selectors.basketTotalItems
      );
      component.productImage = component.element.querySelector(
        component.config.selectors.productImage
      );
      component.productUrls = component.element.querySelectorAll(
        component.config.selectors.productUrl
      );
      component.loyaltyPoints = component.element.querySelector(
        component.config.selectors.loyaltyPoints
      );
      component.defaultButtons = component.element.querySelector(
        component.config.selectors.defaultButtons
      );
      component.personalisedMessage = component.element.querySelector(
        component.config.selectors.personalisedMessage
      );
      component.personalisationSummaryText = component.element.querySelector(
        component.config.selectors.personalisationSummaryText
      );
      component.personalisedButtons = component.element.querySelector(
        component.config.selectors.personalisedButtons
      );
      component.personalisedPrimary = component.element.querySelector(
        component.config.selectors.personalisedPrimary
      );
      component.domParser = new DOMParser();

      uniqueIDHelper.ensureAriaLabelledByIdsAreUnique(component.element);

      if (!userDriven) {
        component.subscribe();
      }

      if (component.closeModalButton) {
        component.closeModalButton.addEventListener(
          'click',
          component.closeModalTracking
        );
      }

      if (component.continueShoppingButton) {
        component.continueShoppingButton.addEventListener(
          'click',
          component.continueShoppingTracking
        );
      }

      if (component.viewBasketButton) {
        component.viewBasketButton.addEventListener(
          'click',
          component.viewBasketTracking
        );
      }

      if (component.personalisedPrimary) {
        component.personalisedPrimary.addEventListener(
          'click',
          component.handlePersonalisationBtn
        );
      }

      component.element.addEventListener(
        'click',
        component.overlayClickHandler
      );

      return component;
    };

    var _subscribe = function () {
      app.subscribe(
        component.config.subscribeChannels.productAdded,
        component.productAddReceived
      );
      app.subscribe(
        component.config.subscribeChannels.showError,
        component.showError
      );
      app.subscribe(
        component.config.subscribeChannels.hideError,
        component.hideError
      );
      app.subscribe(
        component.config.subscribeChannels.reinitialise,
        component.reinitialiseComponent
      );
    };

    var _reinitialiseComponent = function () {
      var newAddedToBasketModalObject = document.querySelector(
        '[data-component=addedToBasketModal]'
      );
      component.init(newAddedToBasketModalObject, true);
    };

    var _showModal = function () {
      app.element.addClass(
        component.config.classes.showModal,
        component.element
      );

      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100vw';

      component.accessibleModalHelper = new accessibleModalHelper(
        component.element,
        () => component.closeModal('keypress', 'Pressed escape key'),
        component.element.querySelector('h2')
      );

      component.viewedModalTracking();
    };

    var _closeModal = function (action, button) {
      app.element.removeClass(
        component.config.classes.showModal,
        component.element
      );
      component.productImage.src = '//:0';
      if (component.recommendationsContainer) {
        component.recommendationsContainer.innerHTML =
          component.config.recommendationsResetHTML;
      }
      component.tracking(action, button);
      component.hideQuantityErrors();

      component.accessibleModalHelper &&
        component.accessibleModalHelper.close();

      if (document.body.style.position === 'fixed') {
        const prevScrollTop = -1 * parseFloat(document.body.style.top);
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, prevScrollTop);
        document.body.style.width = '';
      }

      if (component.onBasketPage) {
        $location.reload();
      }
    };

    /**
     * This associated test passes in Chrome but not PhantomJS.
     * In the spec file it is commented out.
     */
    var _parseAndReturn = function (encodedString) {
      var dom = component.domParser.parseFromString(encodedString, 'text/html');
      return dom.body.textContent;
    };

    var _productAddReceived = function (productData) {
      var progress = 0;
      var totalProgress = 8;

      if (productData.productUrl) {
        for (var i = 0, l = component.productUrls.length; i < l; i++) {
          component.switchProductUrl(
            component.productUrls[i],
            productData.productUrl
          );
        }
        progress++;
      }

      if (productData.productTitle) {
        component.switchProductTitle(productData.productTitle);
        component.switchImageAltTag(productData.productTitle);
        progress++;
      }

      if (
        productData.personalisationPhrase ||
        productData.personalisationName
      ) {
        component.switchPersonalisedMessage(
          productData.personalisationPhrase,
          productData.personalisationName
        );
        component.switchPersonalisedButtons();
      }

      if (productData.personalisationSummaryText) {
        component.switchPersonalisationSummaryText(
          productData.personalisationSummaryText
        );
      }

      if (productData.productImageUrl) {
        component.switchProductImage(productData.productImageUrl);
        progress++;
      }

      if (productData.productQuantity !== null) {
        component.switchProductQuantity(productData.productQuantity);
        progress++;
      }

      if (productData.productPrice) {
        component.switchProductPrice(productData.productPrice);
        progress++;
      }

      if (component.productHasWorthByPrice && productData.worthValue !== '') {
        component.switchProductWorth(
          component.productHasWorthByPrice.getAttribute('data-worth-by-price') +
            '  ' +
            productData.worthValue
        );
      }

      if (productData.basketTotalItems) {
        component.switchBasketTotalItems(productData.basketTotalItems);
        progress++;
      }

      if (productData.basketTotalPrice) {
        component.switchBasketTotalPrice(productData.basketTotalPrice);
        progress++;
      }

      if (productData.productId) {
        component.loadRecommendations(productData.productId);
        progress++;
      }

      if (!isNaN(productData.loyaltySchemePoints)) {
        component.switchLoyaltyPoints(productData.loyaltySchemePoints);
      }

      if (progress === totalProgress) {
        component.showModal();
        app.publish('productQuickbuy/stopLoading', '', false);
      } else {
        app.publish('productQuickbuy/showError', '', false);
      }

      if (
        productData.productQuantity === 0 &&
        productData.productQuantityRequested > 0
      ) {
        component.showQuantityReachedError(productData.lastAddedMaxPerOrder);
      }

      if (
        productData.productQuantity > 0 &&
        productData.productQuantity < productData.productQuantityRequested
      ) {
        var numberFailedToAdd =
          productData.productQuantityRequested - productData.productQuantity;
        component.showQuantityPartiallyReachedError(
          numberFailedToAdd,
          productData.lastAddedMaxPerOrder
        );
      }

      if (
        productData.productQuantity === productData.productQuantityRequested
      ) {
        component.hideQuantityErrors();
      }
    };

    var _switchProductUrl = function (element, url) {
      element.href = component.secureUrl + url;
    };

    var _switchImageAltTag = function (content) {
      component.productImage.alt = content;
    };

    var _switchProductImage = function (content) {
      component.productImage.src = component.cndUrl + content;
    };

    var _switchProductTitle = function (content) {
      component.productTitle.innerText = content;
    };

    var _switchPersonalisedMessage = function (phrase, name) {
      let result;
      result = phrase && !name ? phrase : '';
      result = !phrase && name ? name : result;
      result = phrase && name ? phrase + ' ' + name : result;
      component.personalisedMessage.innerText = result;
      component.personalisedMessage.classList.add(
        component.config.classes.personalisedMessage
      );
    };

    var _switchPersonalisationSummaryText = function (
      personalisationSummaryText
    ) {
      component.personalisationSummaryText.innerText =
        personalisationSummaryText;
      component.personalisationSummaryText.classList.add(
        component.config.classes.personalisedMessage
      );
    };

    var _switchPersonalisedButtons = function () {
      component.defaultButtons.classList.add('hidden');
      component.personalisedButtons.classList.remove('hidden');
    };

    var _switchProductPrice = function (data) {
      component.productPrice &&
        (component.productPrice.innerText = component.parseAndReturn(data));
    };

    var _switchProductWorth = function (data) {
      component.productWorth.innerText = component.parseAndReturn(data);
    };

    var _switchProductQuantity = function (content) {
      component.productQuantity.innerText = content;
    };

    var _switchBasketTotalPrice = function (data) {
      component.basketTotal &&
        (component.basketTotal.innerText = component.parseAndReturn(data));
    };

    var _switchBasketTotalItems = function (content) {
      component.basketTotalItems.innerText = content;
    };

    var _switchLoyaltyPoints = function (points) {
      if (!component.loyaltyPoints) {
        return;
      }

      component.loyaltyPoints.innerText = points;
    };

    var _showError = function () {
      app.element.addClass(component.config.classes.showError, component.error);
    };

    var _hideError = function () {
      app.element.removeClass(
        component.config.classes.showError,
        component.error
      );
    };

    var _showQuantityReachedError = function (maxPerOrder) {
      app.element.addClass(
        component.config.classes.showError,
        component.quantityLimitedReached
      );
      var errorMessage = component.config.messages.maxQuantityReached.replace(
        '%d',
        maxPerOrder
      );
      component.quantityLimitedReached.innerHTML = errorMessage;
    };

    var _showQuantityPartiallyReachedError = function (
      failedToAdd,
      maxPerOrder
    ) {
      app.element.addClass(
        component.config.classes.showWarning,
        component.quantityLimitedPartiallyReached
      );
      var errorMessage = component.config.messages.partialMaxQuantityReached
        .replace('%1', failedToAdd)
        .replace('%2', maxPerOrder);

      component.quantityLimitedPartiallyReached.innerHTML = errorMessage;
    };

    var _hideQuantityErrors = function () {
      app.element.removeClass(
        component.config.classes.showError,
        component.quantityLimitedReached
      );
      app.element.removeClass(
        component.config.classes.showWarning,
        component.quantityLimitedPartiallyReached
      );
    };

    var _tracking = function (action, label) {
      app.publish('tracking/record', 'Product | AddedToBasket', action, label);
    };

    var _viewedModalTracking = function () {
      component.tracking('viewed', 'AddToBasket Modal');
    };

    var _closeModalTracking = function () {
      component.closeModal('clicked', 'close modal');
    };

    var _continueShoppingTracking = function () {
      component.closeModal('clicked', 'continue shopping');
    };

    var _viewBasketTracking = function () {
      component.tracking('clicked', 'view basket');
    };

    var _clickedRecItemTracking = function (e) {
      var target = e.target || e.srcElement;
      var targetProduct = app.element.closest(
        component.config.selectors.productID,
        target
      ).element;
      var targetProductID = app.element.getAttribute(
        'data-product-id',
        targetProduct
      );
      component.tracking('clicked', 'Recommended Item | ' + targetProductID);
    };

    var _loadRecommendations = function (productId) {
      let url =
        '/' +
        productId +
        '.recommendations?renderAddedToBasketModal=false&rctxt=postAddToBasket';

      if (component.element.baseURI.includes('worthByPrice')) {
        url = url + '&worthValue=worthByPrice';
      } else if (component.element.baseURI.includes('noWorth')) {
        url = url + '&worthValue=noWorth';
      }

      app.ajax.get({
        url: url,
        success: component.showRecommendations,
        error: component.loadRecommendationsError,
      });
    };

    var _showRecommendations = function (response) {
      if (component.recommendationsContainer) {
        component.recommendationsContainer.innerHTML = response;
        component.accessibleModalHelper.refresh(component.element);
      }
      if (response === '') {
        app.element.addClass(
          component.config.classes.emptyRecommendations,
          component.recommendationsContainer
        );
      } else {
        component.recsClickHandler();
      }
    };

    var _recsClickHandler = function () {
      component.recommendationsItems = component.element.querySelectorAll(
        component.config.selectors.recommendationsItem
      );
      for (var i = 0, l = component.recommendationsItems.length; i < l; i++) {
        component.recommendationsItems[i].addEventListener(
          'click',
          component.clickedRecItemTracking
        );
      }
    };

    var _loadRecommendationsError = function () {
      component.recommendationsContainer.innerHTML = '';
      app.element.addClass(
        component.config.classes.emptyRecommendations,
        component.recommendationsContainer
      );
    };

    var _overlayClickHandler = function (event) {
      if (event.target === component.element) {
        event.stopPropagation();
        component.closeModal('clicked', 'modal overlay');
      }
    };

    var _handlePersonalisationBtn = function () {
      component.closeModal('clicked', 'continue shopping');
      app.publish('productPersonalisation/openModal');
    };

    component.init = _init;
    component.config = _config;
    component.subscribe = _subscribe;
    component.reinitialiseComponent = _reinitialiseComponent;
    component.closeModal = _closeModal;
    component.showModal = _showModal;
    component.parseAndReturn = _parseAndReturn;
    component.productAddReceived = _productAddReceived;
    component.showError = _showError;
    component.showQuantityReachedError = _showQuantityReachedError;
    component.showQuantityPartiallyReachedError =
      _showQuantityPartiallyReachedError;
    component.hideError = _hideError;
    component.hideQuantityErrors = _hideQuantityErrors;
    component.switchProductUrl = _switchProductUrl;
    component.switchImageAltTag = _switchImageAltTag;
    component.switchProductImage = _switchProductImage;
    component.switchProductTitle = _switchProductTitle;
    component.switchPersonalisedMessage = _switchPersonalisedMessage;
    component.switchPersonalisedButtons = _switchPersonalisedButtons;
    component.switchPersonalisationSummaryText =
      _switchPersonalisationSummaryText;
    component.switchProductPrice = _switchProductPrice;
    component.switchProductWorth = _switchProductWorth;
    component.switchProductQuantity = _switchProductQuantity;
    component.switchBasketTotalPrice = _switchBasketTotalPrice;
    component.switchBasketTotalItems = _switchBasketTotalItems;
    component.switchLoyaltyPoints = _switchLoyaltyPoints;
    component.tracking = _tracking;
    component.viewedModalTracking = _viewedModalTracking;
    component.closeModalTracking = _closeModalTracking;
    component.continueShoppingTracking = _continueShoppingTracking;
    component.viewBasketTracking = _viewBasketTracking;
    component.clickedRecItemTracking = _clickedRecItemTracking;
    component.recsClickHandler = _recsClickHandler;
    component.loadRecommendations = _loadRecommendations;
    component.showRecommendations = _showRecommendations;
    component.loadRecommendationsError = _loadRecommendationsError;
    component.overlayClickHandler = _overlayClickHandler;
    component.handlePersonalisationBtn = _handlePersonalisationBtn;
  };

  return function () {
    if (created) return emptyComponent;
    created = true;
    return new addedToBasketModal();
  };
});
